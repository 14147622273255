import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb el peu més llarg que el diàmetre del capell, de color crema i un poc més ample i gruixut cap a la base. Presenta al peu un anell més o menys persistent, davall el qual existeixen esquames de color semblant a les esquames del capell que són marró grisenc. El capell de fins a 3 cm de diàmetre, primerament és convex i després pla amb un mamelló ample i baix al centre, de color marró fosc i dissociat als marges en esquames. Les làmines són lliures, blanquinoses i un poc ventrudes i les espores són blanques en massa, el·líptiques, de 7-10 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      